import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Switch,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getCurrentUser } from '../../api/InvestorApis/apis';
import { patchClient, postClient } from '../../api/InvestorApis/client';
import {
  ClientPayload,
  ManageClientModalProps,
} from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';

export default function ManageClientModal({
  isOpen,
  onClose,
  clientToEdit,
  onAdded,
}: ManageClientModalProps) {
  const currentUser = useAppStore((state: AppStoreState) => state.currentUser);
  const setCurrentUser = useAppStore(
    (state: AppStoreState) => state.setCurrentUser,
  );
  console.log('Client to edit = ', clientToEdit);
  const { register, handleSubmit, reset } = useForm<ClientPayload>({
    defaultValues: {
      name: clientToEdit ? clientToEdit?.name : '',
      token_address: clientToEdit ? clientToEdit?.token_address : '',
      prelaunch_tokens: clientToEdit ? clientToEdit?.prelaunch_tokens : false,
    },
  });
  useEffect(() => {
    async function loadCurrentUser() {
      try {
        const user = await getCurrentUser();
        console.log('current user = ', currentUser);
        setCurrentUser(user.data);
      } catch (error) {
        alert(error);
      }
    }
    loadCurrentUser();
  }, []);
  useEffect(() => {
    reset({
      name: clientToEdit?.name || '',
      token_address: clientToEdit?.token_address || '',
    });
  }, [clientToEdit]);

  const clientExists = clientToEdit?.name
    ? clientToEdit?.name?.length > 0
    : false;
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [showTokenAddress, setShowTokenAddress] = useState(true);
  const [prelaunchToken, setPrelaunchToken] = useState(false);
  console.log(clientToEdit?.prelaunch_tokens);
  const handleFormSubmit = async (data: ClientPayload) => {
    data.owner = currentUser?.id ?? 0;
    console.log('data = ', data);
    setLoading(true);

    if (clientExists) {
      try {
        const res = await patchClient(clientToEdit?.id || 0, data);
        if (res) {
          setLoading(false);
          toast({
            title: 'Client has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      } catch (error: any) {
        setLoading(false);
        console.log('Something went wrong')
        console.log("Error",error)
        toast({
          title: 'Something went wrong while editing client.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      try {
        const res = await postClient(data);
        if (res) {
          setLoading(false);
          toast({
            title: 'Client has been added successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      } catch (error: any) {
        setLoading(false);
        console.log(error)
        toast({
          title: 'Something went wrong while adding client.',
          description: error.response.data.name[0],
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '500px', '500px', '600px']}
        borderRadius="20px"
        padding={['24px', '36px', '36px']}
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
        maxWidth={['none', 'none', 'none', '600px']}
      >
        <ModalBody padding="0px" position="relative">
          <CloseIcon
            top="0"
            right="0"
            position="absolute"
            boxSize={4}
            onClick={onClose}
            cursor="pointer"
          />

          {loading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Box>
                <Text
                  color="#1A1A1A"
                  fontFamily="Montserrat"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="normal"
                >
                  {clientExists ? 'Edit Client' : ' Add Client'}
                </Text>
              </Box>

              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                style={{ marginTop: '24px' }}
              >
                <VStack spacing={1} align="stretch">
                  <FormControl>
                    <Flex
                      flexDirection={'row'}
                      alignItems={'center'}
                      borderBottom={'1px solid #F2F2F2'}
                      paddingBottom={'16px'}
                    >
                      <FormLabel>
                        <Text
                          color="#1A1A1A"
                          fontSize="16px"
                          fontFamily="Montserrat"
                          fontWeight={500}
                        >
                          Pre-Launch Mode
                        </Text>
                        <Text
                          color="#8C8C8C"
                          fontSize="14px"
                          fontFamily="Montserrat"
                          fontWeight={500}
                        >
                          Add the token address once the token is live to fetch
                          real-time prices, token icons, and market data.
                        </Text>
                      </FormLabel>
                      <Switch
                        defaultChecked={clientToEdit?.prelaunch_tokens}
                        {...register('prelaunch_tokens', {})}
                        colorScheme="green"
                        size="lg"
                        isChecked={!showTokenAddress}
                        onChange={() => {
                          setShowTokenAddress(!showTokenAddress);
                          setPrelaunchToken(!prelaunchToken);
                        }}
                        sx={{
                          '& .chakra-switch__track[data-checked]': {
                            backgroundColor: '#0FBF95',
                          },
                        }}
                      />
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      htmlFor="name"
                      color="#8C8C8C"
                      fontSize="12px"
                      fontFamily="Montserrat"
                    >
                      Client Name
                    </FormLabel>
                    <Input
                      borderRadius="12px"
                      variant="filled"
                      placeholder="Enter client name"
                      id="name"
                      {...register('name', {
                        required: 'Client name is required',
                      })}
                    />
                    {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
                  </FormControl>
                  {showTokenAddress && (
                    <FormControl>
                      <FormLabel
                        htmlFor="token_address"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Token Address
                      </FormLabel>
                      <Input
                        id="token_address"
                        type="text"
                        {...register('token_address', {
                          required: 'Wallet address is required',
                          pattern: {
                            value: /^0x[a-fA-F0-9]{40}$/,
                            message: 'Invalid Ethereum address',
                          },
                        })}
                        placeholder="Enter a token address"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                  )}

                  <Box display="flex" gap="10px" width={'100%'}>
                    <Button
                      marginTop="24px"
                      width="100%"
                      borderRadius="14px"
                      border="1px solid #8C8C8C"
                      background="#FFF"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width="100%"
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      {clientExists ? 'Edit Client' : 'Create Client'}
                    </Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
