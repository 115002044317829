import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Deletew from '../../assets/delete white.png';
import Delete from '../../Icons/Delete';
import { MessagetModalProps } from '../../interfaces/components';
import {
  createComment,
  deleteComment,
  updateComment,
} from '../../api/InvestorApis/investments';

function MessageModal({
  onClose,
  isOpen,
  clientId,
  invesment_Id,
  commentData,
  onDeleteClick,
  onCommentUpdate,
  setIconColor,
}: MessagetModalProps & { setIconColor: (color: string) => void }) {
  const [_isHovered, _setIsHovered] = useState(false);
  const toast = useToast();
  const [comment, setComment] = useState('');
  const [isEditing, setIsEditing] = useState(true);
  const [hasComment, setHasComment] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (commentData && commentData?.id) {
      setHasComment(true);
    }
  }, [commentData]);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        autoResize();
      }, 0);
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpen) {
      // const savedComment = localStorage.getItem(`comment_${clientId}`);

      if (commentData && commentData?.id) {
        setComment(commentData.content);
        setIsEditing(false);
        onCommentUpdate?.(true);
        setIconColor('#BFBFBF');
      } else {
        setIsEditing(true);
        setComment('');
        onCommentUpdate?.(false);
        setIconColor('#0FBF95');
      }
      setShowDeleteConfirm(false);
    }
  }, [isOpen, clientId, onCommentUpdate, setIconColor]);
  const autoResize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleSaveComment = async () => {
    if (invesment_Id) {
      try {
        if (hasComment && commentData?.id) {
          // If the comment exists, update it using PATCH
          const res = await updateComment(commentData.id, comment);
          if (res) {
            toast({
              status: 'success',
              title: 'Comment updated successfully.',
              duration: 3000,
            });
          }
        } else {
          // Otherwise, create a new comment using POST
          const res = await createComment({
            investment: invesment_Id,
            content: comment,
          });
          if (res) {
            toast({
              status: 'success',
              title: 'Comment added successfully.',
              duration: 3000,
            });
          }
        }

        onCommentUpdate?.(true);
        setIconColor('#BFBFBF');
      } catch (error) {
        toast({
          status: 'error',
          title: 'An error occurred while saving the comment.',
          duration: 3000,
        });
      }

      onClose();
    }
  };
  useEffect(() => {
    if (!isOpen) {
      setComment('');
      setIsEditing(true);
      setShowDeleteConfirm(false);
    }
  }, [isOpen]);
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    if (showDeleteConfirm) {
      setShowDeleteConfirm(false);
    } else if (!hasComment) {
      setComment('');
    }
    onClose();
  };

  const handleDeleteClick = async () => {
    if (showDeleteConfirm) {
      if (commentData && commentData?.id) {
        try {
          await deleteComment(commentData.id);
          toast({
            title: 'Comment deleted sucessfully....',
            duration: 3000,
            status: 'success',
          });
        } catch {
          toast({
            title: 'Error while deleting the comment....',
            duration: 3000,
            status: 'error',
          });
        }
        setComment('');
        setIconColor('#0FBF95');
        setIsEditing(true);
        onCommentUpdate?.(false);
        onDeleteClick();
      }
    } else {
      setShowDeleteConfirm(true);
    }
  };

  const renderIcon = () => {
    if (showDeleteConfirm) {
      return <img src={Deletew} alt="Delete Icon" width={16} />;
    }
    if (!hasComment) return undefined;
    return _isHovered ? (
      <img src={Deletew} alt="Hover Icon" width={16} />
    ) : (
      <Delete boxSize={4} />
    );
  };

  const getHeaderText = () => {
    if (hasComment) {
      return isEditing ? 'Edit comment' : 'Comment';
    }
    return 'Add Comment';
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['95vw', '380px', '540px', '540px', '540px']}
        borderRadius="36px"
        padding={['24px', '36px', '36px']}
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <CloseIcon
          top={['5x', '5px', '25px']}
          right={['15px', '15px', '25px']}
          position="absolute"
          onClick={onClose}
          cursor="pointer"
          width={'16px'}
          height={'16px'}
          color={'#8C8C8C'}
        />
        <ModalBody
          padding="0px"
          display="flex"
          flexDirection="column"
          gap={'24px'}
          position="relative"
        >
          {showDeleteConfirm ? (
            <Box
              display="flex"
              gap="14px"
              width="100%"
              flexDirection={'column'}
            >
              <Text
                fontFamily="Montserrat"
                fontSize="16px"
                fontWeight="600"
                color="#1A1A1A"
                textAlign="left"
              >
                Delete client
              </Text>

              <Text
                fontFamily="Montserrat"
                fontSize="16px"
                fontWeight="500"
                color="#1A1A1A"
                lineHeight="24px"
              >
                Are you sure you want to delete this comment? You can't undo
                this action.
              </Text>
            </Box>
          ) : (
            <FormControl>
              <Flex flexDirection={'column'} gap={'24px'}>
                <FormLabel
                  fontFamily="Montserrat"
                  fontSize="20px"
                  fontWeight="700"
                  color="#1A1A1A"
                >
                  {getHeaderText()}
                </FormLabel>

                <Textarea
                  ref={textareaRef}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    autoResize();
                  }}
                  placeholder="Type your comment here"
                  fontFamily="Montserrat"
                  fontSize="16px"
                  fontWeight="500"
                  color={
                    hasComment && isEditing
                      ? 'black'
                      : '#rgba(140, 140, 140, 1)'
                  }
                  minHeight="44px"
                  height={'auto'}
                  backgroundColor={
                    (hasComment && !isEditing) || (hasComment && isEditing)
                      ? 'white'
                      : '#F2F2F2'
                  }
                  borderRadius="12px"
                  border={
                    hasComment && !isEditing
                      ? 'transparent'
                      : '1px solid #8C8C8C'
                  }
                  _placeholder={{ color: '#8C8C8C' }}
                  lineHeight="normal"
                  size="md"
                  _focus={{
                    border: '1px solid',
                    borderColor: 'teal.400',
                    boxShadow: 'none',
                  }}
                  resize="none"
                  overflow="hidden"
                  py={2}
                  isDisabled={!isEditing}
                />
              </Flex>
            </FormControl>
          )}

          <Box
            display="flex"
            gap="14px"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              whiteSpace={['nowrap', 'break-spaces', 'nowrap']}
              leftIcon={renderIcon()}
              color={
                !hasComment
                  ? '#1A1A1A'
                  : showDeleteConfirm
                  ? 'white'
                  : '#DC3545'
              }
              type="submit"
              borderRadius="14px"
              background={
                !hasComment ? '#FFF' : showDeleteConfirm ? '#DC3545' : 'white'
              }
              fontWeight={600}
              fontFamily="Montserrat"
              fontSize={['12px', '12px', '14px']}
              width={'100%'}
              onClick={!hasComment ? handleCancel : handleDeleteClick}
              _hover={{}} // Empty object for no hover effect
              _active={{}}
              padding="14px"
              // onMouseEnter={() => _setIsHovered(true)}
              // onMouseLeave={() => _setIsHovered(false)}
              border={'1px solid #E2E2E2'}
              //no hover effect
              // _hover={{ bg: 'none' }}
            >
              {!hasComment
                ? 'Cancel'
                : showDeleteConfirm
                ? 'Delete Client'
                : ' Delete invesment'}
            </Button>
            {showDeleteConfirm ? (
              <Button
                padding={' 14px'}
                borderRadius={'14px'}
                background={'#FFF'}
                color={'#1A1A1A'}
                fontFamily="Montserrat"
                fontSize={'14px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                border="1px solid #8C8C8C"
                width={'100%'}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            ) : (
              <Button
                padding={' 14px'}
                borderRadius={'14px'}
                background={'#0FBF95'}
                color={'#FFF'}
                fontFamily="Montserrat"
                fontSize={'14px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                onClick={isEditing ? handleSaveComment : handleEditClick}
                border={'1px solid #8C8C8C'}
                width={'100%'}
                _hover={{ bg: '#0FBF95' }}
              >
                {!hasComment
                  ? 'Add comment'
                  : isEditing
                  ? 'Save changes'
                  : 'Edit comment'}
              </Button>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default MessageModal;
