import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import sackCoin from '../../assets/mintmark.png';
import { LiquidateResponse } from '../../interfaces/components';
import { formateDate } from '../../utils/commonFunctions';

interface ClaimItemData {
  sold_for: string;
  claim_value: string;
  profit: string;
  amount: string;
  date: string;
}

const ClaimItem: React.FC<ClaimItemData> = ({
  sold_for,
  claim_value,
  profit,
  amount,
  date,
}) => {
  return (
    <Tr>
      <Td
        paddingTop={'12px'}
        paddingBottom={'0px'}
        paddingLeft={'0px'}
        paddingRight={'0px'}
      >
        <Flex
          padding="14px"
          borderRadius="36px"
          background="#F6F6F6"
          alignItems="center"
          justifyContent="space-around"
        >
          <Flex flex="1" gap="8px" padding={'0px 12px'}>
            <Flex alignItems="center">
              <Box
                width="20px"
                height="20px"
                padding="0px 4px"
                flexDirection="column"
                alignItems="center"
                borderRadius="6px"
                border="1px solid #0FBF95"
                background="#0FBF95"
                color="#FFF"
                display="flex"
              >
                <Text
                  fontSize="12px"
                  fontWeight="700"
                  lineHeight="20px"
                  fontFamily="Montserrat"
                >
                  $
                </Text>
              </Box>
            </Flex>
            <Text>{sold_for}</Text>
          </Flex>

          <Flex flex="1" gap="8px" padding={'0px 12px'}>
            <Flex alignItems="center">
              <Box
                width="20px"
                height="20px"
                padding="0px 4px"
                flexDirection="column"
                alignItems="center"
                borderRadius="6px"
                border="1px solid #0FBF95"
                background="#0FBF95"
                color="#FFF"
                display="flex"
              >
                <Text
                  fontSize="12px"
                  fontWeight="700"
                  lineHeight="20px"
                  fontFamily="Montserrat"
                >
                  $
                </Text>
              </Box>
            </Flex>
            <Text
              fontSize="13px"
              fontWeight="500"
              lineHeight="20px"
              fontFamily="Montserrat"
              color="#1A1A1A"
            >
              {claim_value}
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            gap="8px"
            alignContent={'flex-start'}
            padding={'0px 12px'}
          >
            <Text
              fontSize="13px"
              fontWeight="500"
              lineHeight="20px"
              fontFamily="Montserrat"
              color={parseFloat(profit) < 0 ? 'red' : '#1A1A1A'}
            >
              {parseFloat(profit) < 0
                ? `-$${Math.abs(parseFloat(profit))}`
                : `$${profit}`}
            </Text>
          </Flex>
          <Text
            display="flex"
            flex="1"
            justifyContent="flex-end"
            padding={'0px 12px'}
            fontSize="16px"
            fontWeight="500"
            lineHeight="20px"
            fontFamily="Montserrat"
            color="#1A1A1A"
          >
            {date}
          </Text>

          <Text
            display="flex"
            justifyContent="flex-end"
            padding={'0px 17.5px'}
            fontSize="16px"
            fontWeight="500"
            lineHeight="20px"
            fontFamily="Montserrat"
            color="#1A1A1A"
            width={'fit-content'}
          >
            {amount}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
};

const LiquidationsComponent: React.FC<{
  liquidateData: LiquidateResponse[];
}> = ({ liquidateData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const rowsPerPage = 4;
  const totalPages = Math.ceil(liquidateData.length / rowsPerPage);

  const paginatedData = liquidateData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage,
  );
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const openRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const renderPageNumbers = () => {
    const items = [];

    const renderPageNumber = (page: number) => (
      <Box
        key={page}
        background={page === currentPage ? '#0FBF95' : 'white'}
        borderRadius="full"
        padding={'0px 6px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="20px"
          color={page === currentPage ? 'white' : '#8C8C8C'}
          cursor="pointer"
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Text>
      </Box>
    );

    items.push(renderPageNumber(1));
    if (totalPages > 1) {
      items.push(renderPageNumber(2));
    }

    if (currentPage > 3 && totalPages > 3) {
      items.push(
        <Text key="dots1" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (currentPage > 2 && currentPage < totalPages) {
      items.push(renderPageNumber(currentPage));
    }

    if (currentPage < totalPages - 1 && totalPages > 3) {
      items.push(
        <Text key="dots2" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (totalPages > 2) {
      items.push(renderPageNumber(totalPages));
    }

    return items;
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding={['16px', '16px', '16px', '22px']}
        flex="1"
        gap="16px"
        bg="white"
        borderRadius={['none', '44px']}
        boxShadow={[
          'none',
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
        ]}
        border={['none', '1px solid #F6F6F6']}
        borderTop={'1px solid #F6F6F6'}
      >
        <Flex flexDirection="row" alignItems="center" gap="8px">
          <Image src={sackCoin} alt="" boxSize="28px" />
          <Text
            fontSize="24px"
            fontWeight="600"
            lineHeight="normal"
            fontFamily="Montserrat"
          >
            Liquidations
          </Text>
        </Flex>

        <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
          <Thead display="block" padding={'6px 0px'} width={'100%'}>
            <Tr
              borderRadius="10px"
              // border="solid 0.5px #E2E2E2"
              bg="#F6F6F6"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={'14px'}
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color="#1A1A1A"
                fontSize="13px"
                fontWeight="600"
                lineHeight="20px"
                padding="0px 12px"
                flex="1"
                borderRight="1px solid #E2E2E2"
                whiteSpace="nowrap"
              >
                Sold for (USD)
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color="#1A1A1A"
                fontSize="13px"
                fontWeight="600"
                lineHeight="20px"
                padding="0px 12px"
                flex="1"
                borderLeft="1px solid #E2E2E2"
                whiteSpace="nowrap"
              >
                Claim Value (USD)
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color="#1A1A1A"
                fontSize="13px"
                fontWeight="600"
                lineHeight="20px"
                padding="0px 12px"
                flex="1"
                borderRight="1px solid #E2E2E2"
                borderLeft="1px solid #E2E2E2"
                whiteSpace="nowrap"
              >
                Profit (USD){' '}
                {/* <span style={{ color: 'transparent' }}>(+15%)</span> */}
              </Th>
              <Th
                borderRight="1px solid #E2E2E2"
                textAlign="right"
                fontFamily="Montserrat"
                color="#1A1A1A"
                fontSize="13px"
                fontWeight="600"
                lineHeight="20px"
                padding="0px 12px"
                flex="1"
                whiteSpace="nowrap"
              >
                Date
              </Th>
              <Th
                border="none"
                textAlign="right"
                fontFamily="Montserrat"
                color="#1A1A1A"
                fontSize="13px"
                fontWeight="600"
                lineHeight="20px"
                padding="0px 12px"
                flex="1"
                whiteSpace="nowrap"
              >
                Amount
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((claim, index) => (
              <ClaimItem
                key={index}
                sold_for={claim.sell_value}
                claim_value={claim?.payout?.dollar_value?.toString()}
                //@ts-ignore

                profit={claim.profit}
                date={formateDate(claim?.liquidate_date)} // Put date here
                amount={claim.token_amount?.toString()}
              />
            ))}
          </Tbody>
        </Table>
        <Table variant="unstyled" display={['table', 'table', 'table', 'none']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                position="relative"
                whiteSpace={'nowrap'}
              >
                Sold for (USD)
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '15%',
                    bottom: '15%',
                    width: '1px',
                    background: '#E2E2E2',
                  }}
                />
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'end'}
                whiteSpace={'nowrap'}
              >
                Profit (USD)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((data, index) => (
              <Tr
                key={index}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 4 === 0 ? '0px' : '20px'}
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="20px 20px 14px 20px"
                  onClick={() => openRow(index)}
                  cursor="pointer"
                >
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Flex alignItems="center">
                      <Box
                        width="20px"
                        height="20px"
                        padding="0px 4px"
                        flexDirection="column"
                        alignItems="center"
                        borderRadius="6px"
                        border="1px solid #0FBF95"
                        background="#0FBF95"
                        color="#FFF"
                        display="flex"
                      >
                        <Text
                          fontSize="12px"
                          fontWeight="700"
                          lineHeight="20px"
                          fontFamily="Montserrat"
                        >
                          $
                        </Text>
                      </Box>
                    </Flex>
                    <Text
                      fontSize={'13px'}
                      fontWeight={'500'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.sell_value}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Text
                      fontSize={'13px'}
                      fontWeight={'500'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      ${data.profit}
                    </Text>
                    <ChevronDownIcon
                      color="#8C8C8C"
                      boxSize={6}
                      transform={
                        expandedRowIndex === index ? 'rotate(180deg)' : 'unset'
                      }
                      transition="0.2s all"
                    />
                  </Flex>
                </Box>

                <Collapse in={expandedRowIndex === index} animateOpacity>
                  <Box padding="0px 20px 14px 20px">
                    <Flex
                      justifyContent="space-between"
                      mb="10px"
                      paddingRight={'15px'}
                    >
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Claim Value
                      </Text>
                      <Flex
                        gap={'8px'}
                        alignItems={'center'}
                        paddingRight={'15px'}
                      >
                        <Box
                          width={'20px'}
                          height={'20px'}
                          padding={'0px 4px'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          borderRadius={'6px'}
                          border={'1px solid #0FBF95'}
                          background={'#0FBF95'}
                          color={'#FFF'}
                          display={'flex'}
                        >
                          <Text
                            fontSize={'12px'}
                            fontWeight={'700'}
                            lineHeight={'20px'}
                            fontFamily="Montserrat"
                          >
                            $
                          </Text>
                        </Box>
                        <Text
                          fontSize={'13px'}
                          fontWeight={'500'}
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                          color={'#1A1A1A'}
                        >
                          {data.claim_value}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Amount (AMO)
                      </Text>

                      <Text
                        fontSize={'13px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        paddingRight={'30px'}
                      >
                        {data.token_amount}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Date
                      </Text>

                      <Text
                        fontSize={'13px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        paddingRight={'30px'}
                      >
                        {formateDate(data.liquidate_date)}
                      </Text>
                    </Flex>
                  </Box>
                </Collapse>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <HStack
          spacing="6px"
          justifyContent={'flex-end'}
          display={['none', 'none', 'none', 'flex', 'flex']}
        >
          <IconButton
            aria-label="Previous page"
            icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
            onClick={() => handlePageChange(currentPage - 1)}
            isDisabled={currentPage <= 1}
            variant="ghost"
            _hover={{
              bg: 'gray.100',
              borderRadius: 'full',
            }}
            transition="all 0.2s"
          />

          {renderPageNumbers()}

          <IconButton
            aria-label="Next page"
            icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
            onClick={() => handlePageChange(currentPage + 1)}
            isDisabled={currentPage >= totalPages}
            variant="ghost"
            _hover={{
              bg: 'gray.100',
              borderRadius: 'full',
            }}
            transition="all 0.2s"
          />
        </HStack>
      </Box>
      <HStack
        spacing="6px"
        justifyContent={'center'}
        display={['flex', 'flex', 'flex', 'none', 'none']}
        padding={'16px'}
        borderRadius={'44px'}
        border={'1px solid #EFEFEF'}
        background={'#FFF'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        width={'fit-content'}
        alignSelf={'center'}
      >
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage <= 1}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />

        {renderPageNumbers()}

        <IconButton
          aria-label="Next page"
          icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage >= totalPages}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />
      </HStack>
    </>
  );
};

export default LiquidationsComponent;
